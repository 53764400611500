import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl-next';
import { useCoordination } from '@atlassiansox/engagekit-ts';

import { SpotlightCard, SpotlightPulse } from '@atlaskit/onboarding';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { isEmbeddedConfluence_DO_NOT_USE } from '@atlassian/embedded-confluence/isEmbeddedConfluence';

import { useCoordinationClient } from '@confluence/engagement-provider';

import {
	CHANGEBOARDING_LEARN_MORE_LINK,
	PAGE_OWNERSHIP_EP_MESSAGE,
} from './contentOwnershipConstants';
import { i18n } from './i18n';
import { useIsAllowedToChangeOwner } from './useIsAllowedToChangeOwner';
import {
	CustomHeading,
	OnboardingContainer,
	StyledSpotlightCardWrapper,
} from './ContentOwnershipStyles';

const SPOTLIGHT_TARGET_NAME = 'change-owner-spotlight';

type ContentTogglingSpotlightProps = {
	children: React.ReactNode;
	ownedByAccountId: string | null;
};
export const ChangeOwnerSpotlightWrapper = ({
	children,
	ownedByAccountId,
}: ContentTogglingSpotlightProps) => {
	const coordinationClient = useCoordinationClient();
	const [started, stop] = useCoordination(coordinationClient, PAGE_OWNERSHIP_EP_MESSAGE);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const intl = useIntl();
	const [isOnboardingActive, setIsOnboardingActive] = useState(false);
	const isEmbeddedConfluence = isEmbeddedConfluence_DO_NOT_USE();
	const showChangeOwnerAction = useIsAllowedToChangeOwner(ownedByAccountId);
	const shouldShowOnboarding =
		// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
		!process.env.REACT_SSR && started && showChangeOwnerAction && !isEmbeddedConfluence;

	useEffect(() => {
		if (shouldShowOnboarding) {
			setIsOnboardingActive(true);
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					actionSubject: 'feature',
					action: 'exposed',
					actionSubjectId: 'page.owner.changeboarding.spotlight',
					source: 'ChangeOwnerSpotlightWrapper',
				},
			}).fire();
		} else {
			// This is intended to handle the case where the call to
			// isEmbeddedConfluence_DO_NOT_USE() may return false the
			// first time and then return true.
			setIsOnboardingActive(false);
		}
	}, [shouldShowOnboarding, createAnalyticsEvent]);

	const endOnboarding = () => {
		void stop();
		setIsOnboardingActive(false);
	};

	if (!isOnboardingActive) {
		return <>{children}</>;
	}

	return (
		<OnboardingContainer>
			<SpotlightPulse radius={3}>{children}</SpotlightPulse>
			<StyledSpotlightCardWrapper>
				<SpotlightCard
					key={SPOTLIGHT_TARGET_NAME}
					width={290}
					heading={
						<CustomHeading>{intl.formatMessage(i18n.changeOwnerSpotlightTitle)}</CustomHeading>
					}
					actions={[
						{
							onClick: endOnboarding,
							text: intl.formatMessage(i18n.changeOwnerSpotlightOkButton),
						},
						{
							onClick: () => {
								window.open(CHANGEBOARDING_LEARN_MORE_LINK, '_blank');
							},
							text: intl.formatMessage(i18n.changeOwnerSpotlightLearnMoreButton),
							appearance: 'subtle',
						},
					]}
				>
					<FormattedMessage {...i18n.changeOwnerSpotlightBody} />
				</SpotlightCard>
			</StyledSpotlightCardWrapper>
		</OnboardingContainer>
	);
};
