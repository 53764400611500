import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl-next';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import Avatar from '@atlaskit/avatar/Avatar';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import Tooltip from '@atlaskit/tooltip';

import { ProfileCardWrapper } from '@confluence/profile';

import { i18n } from './i18n';
import type { UserShape } from './types';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledBox = styled.div({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	gap: token('space.025'),

	color: token('color.text.subtle'),
	fontWeight: token('font.weight.regular'),
	borderRadius: token('border.radius'),

	paddingRight: token('space.050'),
	height: '24px',

	'&:hover': {
		backgroundColor: token('color.background.neutral.subtle.hovered'),
		cursor: 'pointer',
	},

	'&:active': {
		backgroundColor: token('color.background.neutral.subtle.pressed'),
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'span[data-testid="badge-style-avatar--inner"]': {
		height: '20px',
		width: '20px',
	},
});

type PageOwnershipBylineContentProps = {
	owner: UserShape | null;
	author: UserShape;
	testId?: string;
	analyticsSource: string;
};

export const BadgeStylePageOwnershipByline = ({
	owner,
	author,
	testId,
	analyticsSource,
}: PageOwnershipBylineContentProps) => {
	const intl = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const pageOwner = owner ?? author;
	const avatarSrc = pageOwner.isAnonymous || !pageOwner.avatarUrl ? undefined : pageOwner.avatarUrl;

	const handleAnalytics = () => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				actionSubject: 'button',
				action: 'clicked',
				actionSubjectId: 'contentOwnerBadge',
				source: analyticsSource,
			},
		}).fire();
	};

	return (
		<Tooltip content={intl.formatMessage(i18n.abbreviatedBylineOwnerMessage)} hideTooltipOnClick>
			{(tooltipProps) => (
				<ProfileCardWrapper
					trigger="click"
					userId={pageOwner.userId}
					ariaLabel={intl.formatMessage(i18n.abbreviatedBylineMainText, {
						user: pageOwner.fullName,
					})}
				>
					<StyledBox
						{...tooltipProps}
						role="button"
						aria-haspopup
						data-testId={testId}
						tabIndex={0}
						onClick={handleAnalytics}
					>
						<Avatar
							src={avatarSrc}
							borderColor="transparent"
							size="xsmall"
							testId="badge-style-avatar"
						/>
						<FormattedMessage
							{...i18n.abbreviatedBylineMainText}
							values={{ user: pageOwner.fullName }}
						/>
					</StyledBox>
				</ProfileCardWrapper>
			)}
		</Tooltip>
	);
};
