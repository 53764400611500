import { FORGE_MODULE_BYLINE, useExtensionList } from '@confluence/forge-ui';
import { useWebItemLocation, SYSTEM_CONTENT_BYLINE } from '@confluence/web-item-location';

// Hook to grab byline app info for live pages
export const useByLineApps = (contentId: string) => {
	const { webItems: connectItems, loading: loadingConnectItems } = useWebItemLocation({
		contentId,
		location: SYSTEM_CONTENT_BYLINE,
	});

	const {
		extensions: forgeItems,
		loading: loadingForgeItems,
		error: forgeError,
	} = useExtensionList({
		moduleType: FORGE_MODULE_BYLINE,
	});

	return {
		hasApps: connectItems?.length > 0 || forgeItems?.length > 0,
		connectItems,
		loadingConnectItems,
		forgeItems,
		loadingForgeItems,
		forgeError,
	};
};
