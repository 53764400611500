import React from 'react';

import { useIsCurrentPageLive } from '@confluence/live-pages-utils/entry-points/useIsCurrentPageLive';
import { expValEquals } from '@confluence/feature-experiments';
import { fg } from '@confluence/feature-gating';

import { BylineContainer, BylineMessageContainer } from './ContentOwnershipStyles';
import { PageOwnershipBylineContent } from './PageOwnershipBylineContent';
import { PageOwnershipBylinePopupHandler } from './PageOwnershipBylinePopupHandler';
import type { UserShape } from './types';

type PageOwnershipBylineProps = {
	owner: UserShape;
	creator: UserShape;
	createdDate: Date | null;
	templateId: string | null;
	contentId: string | null;
	lastOwner: UserShape | null;
};
export const PageOwnershipByline = ({
	owner,
	creator,
	createdDate,
	templateId,
	contentId,
	lastOwner,
}: PageOwnershipBylineProps) => {
	const isLivePage = useIsCurrentPageLive();

	return (
		<BylineContainer>
			<BylineMessageContainer>
				<PageOwnershipBylineContent
					owner={owner}
					templateId={templateId}
					abbreviateBylineText={
						isLivePage ||
						fg('cc_page_experiences_new_editor_byline') ||
						expValEquals('cc-page-experiences-new-renderer-byline', 'cohort', 'test')
					}
				/>
			</BylineMessageContainer>
			{!isLivePage &&
				!fg('cc_page_experiences_new_editor_byline') &&
				!expValEquals('cc-page-experiences-new-renderer-byline', 'cohort', 'test') && (
					<PageOwnershipBylinePopupHandler
						creator={creator}
						createdDate={createdDate}
						ownedByAccountId={owner.userId}
						contentId={contentId}
						lastOwner={lastOwner}
					/>
				)}
		</BylineContainer>
	);
};
