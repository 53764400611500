import type { FC } from 'react';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Subscribe } from 'unstated';
import throttle from 'lodash/throttle';

import { Flex, xcss } from '@atlaskit/primitives';
import { WidthObserver } from '@atlaskit/width-detector';

import { TitleAlignmentType, useTitleContentPropertiesForEditor } from '@confluence/content-topper';
import { Attribution, ErrorBoundary } from '@confluence/error-boundary';
import { isExternalShareRequest } from '@confluence/external-share-utils';
import { AlignmentButtonContainer } from '@confluence/title-area-content-components';

import { useByLineApps } from './useByLineApps';
import { LazyLivePagesByLineApps } from './LivePagesByLineApps';
import { BylineStyledMainDivSingleLine } from './PresentationalComponents';
import { SingleLineBylineToggle } from './SingleLineBylineToggle';

export type SingleLineBylineProps = {
	children?: React.ReactNode;
	contentId: string;
	contentType?: 'page' | 'blogpost';
	isTitleCenterAlignedOverride?: boolean;
};

const singleLineBaseStyles = xcss({
	whiteSpace: 'nowrap',
	width: '100%',
	overflowX: 'hidden',
});

const appsContainerStyles = xcss({
	alignItems: 'center',
	gap: 'space.050',
	overflow: 'hidden',
});

const singleLineWrapStyles = xcss({
	flexWrap: 'wrap',
	overflowX: 'visible',
});

const centerAlignmentStyles = xcss({
	justifyContent: 'center',
	marginLeft: 'auto',
	marginRight: 'auto',
	width: 'initial',
});

const maxWidthStyles = xcss({
	maxWidth: '760px',
});

// when the last byline app is on the first row of the byline, its top position should be within this offset
// this is assuming no byline items are wrapping themselves (which they shouldn't be)
const BYLINE_SINGLE_ROW_TOP_OFFSET = 15;
const AppsContainer = ({ children }) => <Flex xcss={appsContainerStyles}>{children}</Flex>;

export const SingleLineByLine: FC<SingleLineBylineProps> = memo(
	({ contentId, children, contentType = 'page', isTitleCenterAlignedOverride }) => {
		const [shouldShowToggleButton, setShouldShowToggleButton] = useState(false);
		const [haveAppsLoaded, setHaveAppsLoaded] = useState(false);
		const [isExpanded, setIsExpanded] = useState(true);
		const [currentParentWidth, setCurrentParentWidth] = useState<number | undefined>();
		const isExternalShare = isExternalShareRequest();
		const bylineRef = useRef<HTMLDivElement | null>(null);
		const { isTitleCenteredInitial } = useTitleContentPropertiesForEditor({
			contentId,
			contentType,
		});

		const {
			hasApps,
			connectItems,
			forgeItems,
			forgeError,
			loadingConnectItems,
			loadingForgeItems,
		} = useByLineApps(contentId);

		useEffect(() => {
			if (!bylineRef.current || !haveAppsLoaded) return;

			const forgeApps = Array.from(
				bylineRef.current.querySelectorAll('[data-testid="forge-byline-header-wrapper"]'),
			);
			const webItemApps = Array.from(
				bylineRef.current.querySelectorAll('[data-testid="web-item-link"]'),
			);

			// forge items are rendered after web items so theyd be last if rendered
			const appsList = [...webItemApps, ...forgeApps];
			const lastAppElement = appsList[appsList.length - 1];

			if (!lastAppElement) return;

			const bylineRefRect = bylineRef.current?.getBoundingClientRect();
			const lastAppRect = lastAppElement.getBoundingClientRect();
			const areOnSameLine =
				Math.abs(bylineRefRect.top - lastAppRect.top) < BYLINE_SINGLE_ROW_TOP_OFFSET;

			if (isExpanded) {
				// theres no need to calculate overflow below if the apps are already expanded
				// we just need to check if all apps fit in the first row so we can hide the toggle button
				setShouldShowToggleButton(!areOnSameLine);
				return;
			}

			// Check if elements are on the same line by comparing their top positions
			setShouldShowToggleButton(lastAppRect.right > bylineRefRect.right);
		}, [currentParentWidth, haveAppsLoaded, isExpanded]);

		const toggleShowAllApps = () => {
			setIsExpanded((currentState) => !currentState);
		};

		const handleBylineAppsLoaded = useCallback(() => {
			setHaveAppsLoaded(true);
		}, []);

		const updateContentAreaWidth = throttle((width: number) => {
			setCurrentParentWidth(width);
		}, 100);

		// this container is necessary to prevent the byline overflow from pushing the show more button out of view
		const RestrictAppsInOverflowContainer = isExpanded ? React.Fragment : AppsContainer;

		const shouldShowApps = !isExternalShare && hasApps;

		return (
			<Subscribe to={[AlignmentButtonContainer]}>
				{(alignmentButtonContainer: AlignmentButtonContainer) => {
					const isTitleCenterAligned =
						isTitleCenterAlignedOverride ||
						(!alignmentButtonContainer.state.isLoadingComplete
							? isTitleCenteredInitial
							: alignmentButtonContainer.state.alignmentButtonState === TitleAlignmentType.CENTER);

					return (
						<BylineStyledMainDivSingleLine isExpanded={isExpanded}>
							<Flex
								alignItems="center"
								testId="byline-single-line"
								ref={bylineRef}
								xcss={[
									singleLineBaseStyles,
									// show more toggles to enable wrap are dependent on having apps present
									// this would prevent an edge case of a very long byline (or tiny viewport) with no apps being cut off
									(isExpanded || !hasApps) && singleLineWrapStyles,
									isTitleCenterAligned && centerAlignmentStyles,
									isTitleCenterAligned && !isExpanded && maxWidthStyles,
								]}
								gap="space.050"
							>
								<>
									{children}
									{shouldShowApps && (
										<ErrorBoundary attribution={Attribution.ECOSYSTEM}>
											<RestrictAppsInOverflowContainer>
												<LazyLivePagesByLineApps
													contentId={contentId}
													connectItems={connectItems}
													forgeItems={forgeItems}
													forgeError={forgeError}
													showLivePagesExtensionPoints
													isSingleLineByline
													onBylineAppsLoaded={handleBylineAppsLoaded}
													loadingConnectItems={loadingConnectItems}
													loadingForgeItems={loadingForgeItems}
													noContainer
												/>
											</RestrictAppsInOverflowContainer>
										</ErrorBoundary>
									)}
								</>
								{shouldShowApps && shouldShowToggleButton && (
									<SingleLineBylineToggle
										isExpanded={isExpanded}
										handleToggle={toggleShowAllApps}
									/>
								)}
							</Flex>
							<WidthObserver setWidth={updateContentAreaWidth} />
						</BylineStyledMainDivSingleLine>
					);
				}}
			</Subscribe>
		);
	},
);
