import { useWindowSize } from '@confluence/dom-helpers';
import { fg } from '@confluence/feature-gating';
import { useRenderServerPlaceholder } from '@confluence/ssr-utilities';

import { TagButtonUIVariant } from '../constants/TagButtonUIVariant';

type UseGetClassificationTagUIVariantArgs = {
	contentTitle?: string;
	classificationName?: string;
};

export const WINDOW_WIDTH_THRESHOLD = 1000;

const CONTENT_TITLE_MAX_LENGTH = 25;
const CLASSIFICATION_NAME_MAX_LENGTH = 25;
const TOTAL_MAX_LENGTH = CONTENT_TITLE_MAX_LENGTH + CLASSIFICATION_NAME_MAX_LENGTH;

// ~ "Add classification", in English
const APPROX_ADD_BUTTON_CHAR_LENGTH = 18;

export const useGetClassificationTagUIVariant = ({
	contentTitle = '',
	classificationName = '',
}: UseGetClassificationTagUIVariantArgs) => {
	const { width: windowWidth } = useWindowSize();
	const renderServerPlaceholder = useRenderServerPlaceholder();

	const contentTitleLength = contentTitle.length;
	const tagLabelLength =
		classificationName === '' ? APPROX_ADD_BUTTON_CHAR_LENGTH : classificationName.length;
	const combinedLength = contentTitleLength + tagLabelLength;

	const isIconOnly = fg('confluence_frontend_object_header')
		? windowWidth < WINDOW_WIDTH_THRESHOLD
		: windowWidth < WINDOW_WIDTH_THRESHOLD && combinedLength > TOTAL_MAX_LENGTH;

	// window is not availble in SSR so we need to need to render
	// the icon only while hydrating
	return isIconOnly || renderServerPlaceholder
		? TagButtonUIVariant.iconOnly
		: TagButtonUIVariant.default;
};
