import { Container } from 'unstated';

import { TitleAlignmentType } from '@confluence/content-topper';

export class AlignmentButtonContainer extends Container<any> {
	state = {
		alignmentButtonState: TitleAlignmentType.LEFT,
		isUpdatingAlignment: false,
		isLoadingComplete: false,
	};

	toggleAlignmentButton = (newAlignmentState?: TitleAlignmentType) => {
		if (this.state.alignmentButtonState !== newAlignmentState) {
			void this.setState({
				alignmentButtonState: newAlignmentState || TitleAlignmentType.LEFT,
			});
		}
	};

	setIsLoadingComplete = (isLoadingComplete: boolean) => {
		void this.setState({ isLoadingComplete });
	};
	setIsUpdatingAlignment = (isUpdatingAlignment: boolean) => {
		void this.setState({ isUpdatingAlignment });
	};
	resetToDefaults = () => {
		void this.setState({
			alignmentButtonState: TitleAlignmentType.LEFT,
			isUpdatingAlignment: false,
			isLoadingComplete: false,
		});
	};
}
