import gql from 'graphql-tag';

export const TitleContentPropertyMutation = gql`
	mutation TitleContentPropertyMutation(
		$contentId: String!
		$propertyKey: String!
		$propertyValue: String
		$versionNumber: String
		$isDelete: Boolean
	) @experimental {
		experimentalUpdateContentProperty(
			contentId: $contentId
			propertyKey: $propertyKey
			propertyValue: $propertyValue
			versionNumber: $versionNumber
			isDelete: $isDelete
		) {
			success
			id
			key
			value
		}
	}
`;
