import type { PropsWithChildren } from 'react';
import React from 'react';
import { Subscribe } from 'unstated';

import Spinner from '@atlaskit/spinner';
import { Inline } from '@atlaskit/primitives';

import { FlagsStateContainer } from '@confluence/flags';

import { FlagStatus } from '../constants/FlagStatus';
import { SaveFlag } from '../SaveFlag';

import { sharedMessages } from './sharedMessages';

type TagLoadingAndFlagWrapperProps = {
	flagStatus?: FlagStatus;
	isLoading?: boolean;
};

export const TagLoadingAndFlagWrapper = ({
	children,
	flagStatus,
	isLoading = false,
}: PropsWithChildren<TagLoadingAndFlagWrapperProps>) => {
	return (
		<Inline alignBlock="center" space="space.050">
			{children}
			{isLoading && <Spinner size="small" />}
			<Subscribe to={[FlagsStateContainer]}>
				{(flags: FlagsStateContainer) =>
					flagStatus === FlagStatus.ERROR && (
						<SaveFlag
							flags={flags}
							title={sharedMessages.saveErrorTextPrimary}
							description={sharedMessages.saveErrorTextSecondary}
							type="warning"
							showRefresh={false}
						/>
					)
				}
			</Subscribe>
		</Inline>
	);
};
