import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

import { ContentTypeClassification } from '@confluence/data-classification/entry-points/ContentTypeClassification';
import { ClassificationEditModalLazyLoader as ClassificationEditModal } from '@confluence/data-classification/entry-points/ClassificationEditModal';
import { useGetPageMode } from '@confluence/page-utils/entry-points/useGetPageMode';
import { isExternalShareRequest } from '@confluence/external-share-utils';
import { PageMode } from '@confluence/page-utils/entry-points/enums';
import { fg } from '@confluence/feature-gating';
import {
	VALID_OPERATION_TARGETS,
	getContentMode,
	type ContentType,
} from '@confluence/content-types-utils';
import { useContentClassification } from '@confluence/data-classification/entry-points/queries';
import {
	useIsClassificationEnabledForContentTypeExpansion,
	useGetClassificationTagUIVariant,
} from '@confluence/data-classification/entry-points/hooks';
import { ErrorDisplay } from '@confluence/error-boundary';

type BylineClassificationProps = {
	contentId: string;
	isReadOnly?: boolean;
};

export const BylineClassification = ({ contentId, isReadOnly }: BylineClassificationProps) => {
	// reusing the contentypes header query since it'll be cached already
	const { data, error: BylineClassificationQueryError } = useQuery(
		gql`
			query BylineClassificationQuery($contentId: ID) {
				content(id: $contentId, status: ["archived", "current", "draft"]) {
					nodes {
						id
						title
						operations {
							operation
							targetType
						}
						space {
							id
							name
							key
							alias
						}
						type
						status
					}
				}
			}
		`,
		{
			variables: { contentId },
		},
	);
	const contentData = data?.content?.nodes?.[0];
	const spaceKey = contentData?.space?.key || '';
	const isExternal = isExternalShareRequest();

	// Different pieces of content can have different modes, which need to be reflected in this header.
	// Eg, pages & blogposts can be 'draft', 'archived', 'view', 'edit', or 'live' (or 'embedded', or 'legacy'!)
	const rawContentType = contentData?.type ?? undefined; // ShareButton's shareContentType prop doesn't like null values
	const isArchived = contentData?.status === 'archived';
	const isDraft = contentData?.status === 'draft';
	const isViewEditOrLive = useGetPageMode();

	const contentType = {
		type: rawContentType,
		mode: getContentMode(rawContentType, isArchived, isDraft, isViewEditOrLive),
	};

	const isClassificationEnabled = useIsClassificationEnabledForContentTypeExpansion(
		contentData?.type ?? '',
	);

	let contentStatusContext;
	if (contentType.mode === 'live') {
		contentStatusContext = 'current-and-draft' as const;
	} else if (contentType.mode === 'edit' || contentType.mode === 'draft') {
		contentStatusContext = 'draft-only' as const;
	} else {
		contentStatusContext = 'current-only' as const;
	}

	const readOnly =
		isReadOnly ||
		isExternal ||
		!contentData?.operations?.some(
			(operation) =>
				operation?.targetType &&
				VALID_OPERATION_TARGETS.includes(operation?.targetType as ContentType) &&
				operation?.operation === 'update',
		) ||
		contentType.mode === PageMode.VIEW;

	const contentClassificationMetadata = useContentClassification({
		contentId,
		spaceKeyOverride: contentData?.space?.key,
		contentStatusContext,
		shouldSkipQueries: !isClassificationEnabled,
		shouldReadFromQueries: true,
	});
	const classificationTagUiVariant = useGetClassificationTagUIVariant({
		contentTitle: contentData?.title ?? '',
		classificationName: contentClassificationMetadata.classification?.name,
	});

	if (BylineClassificationQueryError) {
		return <ErrorDisplay error={BylineClassificationQueryError} />;
	}

	return isClassificationEnabled ? (
		<>
			<ContentTypeClassification
				variant={classificationTagUiVariant}
				spaceKey={spaceKey}
				contentId={contentId}
				contentType={contentType.type}
				contentClassificationMetadata={contentClassificationMetadata}
				readOnly={readOnly}
				spacing={fg('cc_page_experiences_byline_badge_style') ? 'expanded' : 'default'}
			/>

			<ClassificationEditModal
				contentId={contentId}
				spaceKey={spaceKey}
				contentClassificationMetadata={contentClassificationMetadata}
			/>
		</>
	) : null;
};
