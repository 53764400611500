import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl-next';

import { token } from '@atlaskit/tokens';

import { fg } from '@confluence/feature-gating';
import { PoweredByTemplates } from '@confluence/powered-by-templates';
import { ProfileLink, ProfileCardWrapper } from '@confluence/profile';

import { i18n } from './i18n';
import type { UserShape } from './types';

type PageOwnershipBylineContentProps = {
	owner: UserShape;
	templateId: string | null;
	abbreviateBylineText?: boolean;
};
export const PageOwnershipBylineContent = ({
	owner,
	templateId,
	abbreviateBylineText,
}: PageOwnershipBylineContentProps) => {
	const style = {
		color: token('color.text.subtle'),
	};
	const intl = useIntl();

	const bylineTextTemplate = abbreviateBylineText
		? i18n.abbreviatedBylineMainText
		: i18n.bylineMainTextWithTemplate;

	const bylineTextNonTemplate = abbreviateBylineText
		? i18n.abbreviatedBylineMainText
		: i18n.bylineMainText;

	const withTemplate = intl.formatMessage(i18n.poweredByTemplates);
	const ownerLabel = templateId
		? intl.formatMessage(bylineTextTemplate, { user: owner.fullName, withTemplate })
		: intl.formatMessage(bylineTextNonTemplate, { user: owner.fullName });

	const linkOnly = (
		<ProfileLink
			userId={owner.userId}
			fullName={owner.fullName}
			isAnonymous={owner.isAnonymous}
			referralSource="profilecard"
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			style={style}
			ariaLabel={ownerLabel}
		/>
	);
	const profileLink = fg('confluence_show_profile_card_on_name_hover') ? (
		<ProfileCardWrapper userId={owner.userId} ariaLabel={ownerLabel}>
			{linkOnly}
		</ProfileCardWrapper>
	) : (
		linkOnly
	);

	return templateId ? (
		<FormattedMessage
			{...bylineTextTemplate}
			values={{
				user: profileLink,
				withTemplate: <PoweredByTemplates templateId={templateId} />,
			}}
		/>
	) : (
		<FormattedMessage {...bylineTextNonTemplate} values={{ user: profileLink }} />
	);
};
