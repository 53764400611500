import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledMainDiv = styled.div<{ isTitleCenterAligned?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'@keyframes show': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'0%': {
			opacity: 0,
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'100%': {
			opacity: 1,
		},
	},
	font: token('font.body.small'),
	color: token('color.text.subtle'),
	/* update next/packages/content-title/src/ContentTitlePlaceholder.js when this changes */
	margin: `${token('space.250')} 0 ${token('space.250')} 0`,
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	textAlign: (p) => (p.isTitleCenterAligned ? 'center' : 'inherit'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LivePagesBylineStyledMainDiv = styled(StyledMainDiv)<{ hasFixedHeight?: boolean }>({
	alignItems: 'center',
	margin: token('space.0'),
	flexGrow: 1,
	zIndex: '100',
	whiteSpace: 'nowrap',

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	height: (props) => (!!props.hasFixedHeight ? '32px' : 'initial'), // fixed height value MUST match bylineStyles in LivePagesByLinePlaceholderComponent.tsx
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const BylineStyledMainDivSingleLine = styled(LivePagesBylineStyledMainDiv)<{
	isExpanded?: boolean;
}>({
	width: '100%',
	// relative is necessary for widthobserver to detect this as the parent and provide width of content area
	position: 'relative',
	// zindex causes the byline to be above the space nav and there doesnt seem to be an apparent need for it
	zIndex: 'auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'[data-testid="byline-single-line"]': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
		marginTop: (props) => (props.isExpanded ? '1px' : '0'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledAvatars = styled.div<{ isTitleCenterAligned?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	margin: (p) => (p.isTitleCenterAligned ? '0px 7px 0px 0px' : '3px 0px 0px 0px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledTextDiv = styled.div<{ hasBadgeStylePageOwnerBylineItem?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	margin: (props) => (!!props.hasBadgeStylePageOwnerBylineItem ? 0 : `0 0 0 ${token('space.100')}`),
	/* IE11 */
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	a: {
		textDecoration: 'none',
		color: token('color.link'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'a:focus, a:hover, a:visited, a:active': {
		color: token('color.link.pressed'),
		textDecoration: 'underline',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const BylineStyledTextDiv = styled(StyledTextDiv)({
	display: 'flex',
	alignItems: 'center',
	marginTop: token('space.025'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledAuthorDiv = styled.div<{ isTitleCenterAligned?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	display: (p) => (p.isTitleCenterAligned ? 'flex' : 'block'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	justifyContent: (p) => (p.isTitleCenterAligned ? 'center' : 'initial'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	alignItems: (p) => (p.isTitleCenterAligned ? 'center' : 'initial'),
	/* need to specifically override 'a' T_T */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'a:first-of-type': {
		textDecoration: 'none',
		color: token('color.link'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'a:focus, a:hover, a:visited, a:active': {
		color: token('color.link.pressed'),
		textDecoration: 'underline',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledNameSkeleton = styled.div({
	display: 'inline-block',
	background: token('color.skeleton'),
	width: '100px',
	height: '14px',
	borderRadius: '3px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const CommentCountContainer = styled.a({
	cursor: 'pointer',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'& :before': {
		content: '•',
		display: 'inline-block',
		color: token('color.background.accent.gray.subtle'),

		padding: '0 7px 0 5px',
		textDecoration: 'none',
		/* for IE <= 11 */
	},
});
