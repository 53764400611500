import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { IconButton } from '@atlaskit/button/new';
import ChevronLeftIcon from '@atlaskit/icon/utility/chevron-left';
import ChevronRightIcon from '@atlaskit/icon/utility/chevron-right';
import { Box, Pressable, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

export interface SingleLineBylineToggleProps {
	isExpanded: boolean;
	handleToggle: () => void;
}

const i18n = defineMessages({
	showAllApps: {
		id: 'content-header.livepages.by-line.showAllApps',
		defaultMessage: 'Show all apps',
		description: 'Label for an icon button to show all hidden apps',
	},
	showLessApps: {
		id: 'content-header.livepages.by-line.showLess',
		defaultMessage: 'Show less',
		description: 'Message for an icon button to show fewer apps',
	},
});

const toggleButtonGradientStyles = xcss({
	height: '100%',
	position: 'relative',
	'::before': {
		content: '""',
		background: `linear-gradient(to right, transparent, ${token('elevation.surface')})`,
		height: '100%',
		left: 'space.negative.400',
		position: 'absolute',
		width: '32px',
	},
});

const buttonStyles = xcss({
	alignItems: 'center',
	backgroundColor: 'color.background.neutral.subtle',
	borderRadius: '4px',
	color: 'color.text.subtle',
	display: 'flex',
	font: 'font.body.small',
	gap: 'space.100',
	padding: 'space.050',
	whiteSpace: 'nowrap',

	':hover': {
		backgroundColor: 'color.background.neutral.subtle.hovered',
	},
	':active': {
		backgroundColor: 'color.background.neutral.subtle.pressed',
	},
});

export const SingleLineBylineToggle: React.FC<SingleLineBylineToggleProps> = ({
	isExpanded,
	handleToggle,
}) => {
	const intl = useIntl();
	return (
		<Box
			xcss={!isExpanded && toggleButtonGradientStyles}
			testId="single-line-byline-toggle-wrapper"
		>
			{!isExpanded ? (
				<IconButton
					appearance="subtle"
					spacing="compact"
					icon={ChevronRightIcon}
					label={intl.formatMessage(i18n.showAllApps)}
					onClick={handleToggle}
					testId="show-all-apps-button"
				/>
			) : (
				<Pressable xcss={buttonStyles} onClick={handleToggle} testId="show-less-apps-button">
					<ChevronLeftIcon label="" />
					{intl.formatMessage(i18n.showLessApps)}
				</Pressable>
			)}
		</Box>
	);
};
