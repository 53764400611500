import React, { useCallback } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { NetworkStatus } from 'apollo-client';

import { usePubSubEvent } from '@confluence/pubsub-client';
import type { FlagsStateContainer } from '@confluence/flags';
import { getMonitoringClient } from '@confluence/monitoring';
import { Attribution, isUnauthorizedError, isNotFoundError } from '@confluence/error-boundary';
import { markErrorAsHandled } from '@confluence/graphql';

import { TitleContentPropertyMutation } from './TitleContentPropertyMutation.experimentalgraphql';
import { TitleContentPropertyQuery } from './TitleContentPropertyQuery.graphql';
import { contentPropertyHelper } from './contentPropertyHelper';
import type { TitleContentPropertyMutation as TitleContentPropertyMutationType } from './__types__/TitleContentPropertyMutation';
import type {
	TitleContentPropertyQuery as TitleContentPropertyQueryType,
	TitleContentPropertyQueryVariables,
} from './__types__/TitleContentPropertyQuery';
import {
	TitleAlignmentType,
	defaultPropertyValue,
	COVERPICTUREWIDTH,
} from './contentTopperConstants';

type UseTitleContentPropertiesForEditorProps = {
	contentId: string;
	propertyKey?: string;
	isDelete?: boolean;
	loadTitleContentProperties?: boolean;
	updateAlignmentState?: (alignment?: TitleAlignmentType) => void;
	updateCoverPictureWidthState?: (coverPictureWidth: COVERPICTUREWIDTH) => void;
	contentType: 'page' | 'blogpost' | null;
	flags?: FlagsStateContainer;
	updateIsLoadingComplete?: (isLoadingComplete: boolean) => void;
};

export const getPageTitleDraftTitlePropertyContent = (
	queryData: TitleContentPropertyQueryType | null,
) =>
	queryData?.content?.nodes?.[0]?.pageTitleDraft?.nodes?.find(
		(node) => node?.key === 'page-title-property-draft',
	);

// eslint-disable-next-line check-i18n-id-prefix/i18n-id-prefix-with-package-name-defineMessages
const i18n = defineMessages({
	errorFlagTitle: {
		id: 'custom-sites-extensions.page-title.alignment.error.title',
		defaultMessage: 'We couldn’t update the title alignment',
		description:
			"Title for error flag when a user tries to change title alignment and there's an error",
	},
	errorFlagDescription: {
		id: 'custom-sites-extensions.page-title.alignment.error.description',
		defaultMessage: 'Try again, or refresh the page.',
		description:
			"Description for error flag when a user tries to change title alignment and there's an error",
	},
});

export const useTitleContentPropertiesForEditor = ({
	contentId,
	propertyKey = 'page-title-property-draft',
	isDelete = false,
	loadTitleContentProperties = false,
	updateAlignmentState,
	updateCoverPictureWidthState,
	contentType,
	flags,
	updateIsLoadingComplete,
}: UseTitleContentPropertiesForEditorProps) => {
	const isInitialLoadComplete = React.useRef(false);
	const isTitleCenteredInitial = React.useRef(false);

	const handleError = useCallback(
		(error: any, suppressErrors: boolean) => {
			const isUnauthOrNotFoundError = isUnauthorizedError(error) || isNotFoundError(error);
			if (!isUnauthOrNotFoundError) {
				getMonitoringClient().submitError(error, {
					attribution: Attribution.COMPANY_HUB,
				});
			}
			markErrorAsHandled(error);
			if (!suppressErrors) {
				void flags?.showErrorFlag({
					title: <FormattedMessage {...i18n.errorFlagTitle} />,
					description: <FormattedMessage {...i18n.errorFlagDescription} />,
				});
			}
		},
		[flags],
	);

	const getAlignmentFromContentProperty = (response: any): TitleAlignmentType => {
		const titlePropertyContent = getPageTitleDraftTitlePropertyContent(response);
		if (titlePropertyContent?.value) {
			try {
				return (
					contentPropertyHelper(titlePropertyContent)?.titleLayoutAlignment ||
					defaultPropertyValue.titleLayoutAlignment
				);
			} catch (e) {
				handleError(e, false);
				return defaultPropertyValue.titleLayoutAlignment;
			}
		} else {
			return defaultPropertyValue.titleLayoutAlignment;
		}
	};

	const onCompleted = (response: any) => {
		const coverPictureWidth: COVERPICTUREWIDTH =
			response.content?.nodes?.[0]?.metadata?.frontend?.coverPictureWidth || COVERPICTUREWIDTH.FULL;
		void updateAlignmentState?.(getAlignmentFromContentProperty(response));
		void updateCoverPictureWidthState?.(coverPictureWidth);
	};

	const {
		data,
		networkStatus,
		refetch: refetchProperties,
	} = useQuery<
		TitleContentPropertyQueryType,
		TitleContentPropertyQueryVariables
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
	>(TitleContentPropertyQuery, {
		variables: {
			contentId,
			status: ['current', 'draft', 'archived'],
		},
		fetchPolicy: Boolean(window.__SSR_RENDERED__) ? 'cache-first' : 'cache-and-network', // cache-and-network is required for: edit cover width, transition out of edit, transition back to edit for same page (for Live Docs and Classic pages)
		onError: (error) => {
			handleError(error, true);
		},
		skip: !(loadTitleContentProperties || Boolean(window.__SSR_RENDERED__)),
		notifyOnNetworkStatusChange: true,
	});
	const isTitleAlignmentLoading = networkStatus === NetworkStatus.loading && !data;
	const refetchLoading = networkStatus === NetworkStatus.refetch;

	isTitleCenteredInitial.current =
		(Boolean(window.__SSR_RENDERED__) || !isTitleAlignmentLoading) &&
		getAlignmentFromContentProperty(data) === TitleAlignmentType.CENTER;
	if (!isInitialLoadComplete.current && loadTitleContentProperties && data) {
		isInitialLoadComplete.current = true;
		onCompleted(data);
		updateIsLoadingComplete?.(true);
	}

	const [updateProperties, { loading: isUpdatingAlignment }] =
		useMutation<TitleContentPropertyMutationType>(
			// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
			TitleContentPropertyMutation,
		);

	const onUpdatedPageTitleProperty = useCallback(
		(_eventName: any, _pubSubData: any) => {
			try {
				const newContentState = JSON.parse(_pubSubData?.contentPropertyUpdateRequestBody?.value);
				void updateAlignmentState?.(newContentState?.titleLayoutAlignment);
			} catch (error) {
				handleError(error, false);
			}
		},
		[updateAlignmentState, handleError],
	);

	usePubSubEvent({
		contentId,
		contentType: contentType || 'page',
		eventName: 'updated:pageTitleProperty',
		onEvent: onUpdatedPageTitleProperty,
	});

	const updatePageTitleProperty = useCallback(
		async (propertyValue: any) => {
			try {
				const getVersionNumber = (data: any) =>
					data?.content?.nodes?.[0]?.pageTitleDraft?.nodes?.[0]?.version?.number || 0;
				const versionData = await refetchProperties();
				const versionNumber = getVersionNumber(versionData.data);
				const newVersionNumber = versionNumber ? versionNumber + 1 : 1;

				const optimisticResponse = {
					__typename: 'Mutation',
					experimentalUpdateContentProperty: {
						__typename: 'experimentalUpdateContentProperty',
						success: true,
						id: contentId,
						key: propertyKey,
						value: propertyValue,
					},
				};
				await updateProperties({
					variables: {
						contentId,
						propertyKey,
						isDelete,
						propertyValue,
						versionNumber: String(newVersionNumber),
					},
					optimisticResponse,
					update: (proxy: any) => {
						try {
							const cacheData = proxy.readQuery({
								query: TitleContentPropertyQuery,
								variables: {
									contentId,
									status: ['current', 'draft', 'archived'],
								},
							});

							cacheData!.content!.nodes![0]!.pageTitleDraft!.nodes![0] = {
								...cacheData!.content!.nodes![0]!.pageTitleDraft!.nodes![0],
								id: contentId,
								key: propertyKey,
								value: JSON.stringify(propertyValue),
								version: {
									number: newVersionNumber,
									__typename: 'Version',
								},
							};

							proxy.writeQuery({
								query: TitleContentPropertyQuery,
								variables: {
									contentId,
									status: ['current', 'draft', 'archived'],
								},
								data,
							});
						} catch (error) {
							throw new Error(
								` failed to write to cache while updating ${propertyKey} content property after user updated page title${error.message}`,
							);
						}
					},
				});
			} catch (err) {
				handleError(err, false);
			}
		},
		[propertyKey, contentId, isDelete, updateProperties, refetchProperties, data, handleError],
	);

	return {
		isUpdatingAlignment: isUpdatingAlignment || refetchLoading,
		updatePageTitleProperty,
		// exported for testing purposes
		onCompleted,
		isTitleAlignmentLoading,
		isTitleCenteredInitial: isTitleCenteredInitial.current,
	};
};
