import type { FC } from 'react';
import React, { useEffect } from 'react';
import type { MessageDescriptor } from 'react-intl-next';
import { FormattedMessage, defineMessage } from 'react-intl-next';

import type { FlagType, FlagsStateContainer } from '@confluence/flags';
import { Attribution, withTransparentErrorBoundary } from '@confluence/error-boundary';

import { NO_CLASSIFICATION } from './constants/NoClassification';

const actionMessage = defineMessage({
	id: 'data-classification.settings-panel.error-flag-action',
	defaultMessage: 'Refresh page',
	description: 'Body for error flag',
});

export type SaveFlagProps = {
	flags: FlagsStateContainer;
	type: FlagType;
	title: MessageDescriptor;
	description: MessageDescriptor;
	classification?: string;
	spaceName?: string;
	showRefresh: boolean;
};

// Responsible for all flags within @confluence/data-classification
// (i.e. the success & error flags for updating space classification level in Space Settings and error flag for updating a content's classification level)
export const SaveFlagComponent = ({
	flags,
	type,
	title,
	description,
	classification,
	spaceName,
	showRefresh,
}: SaveFlagProps) => {
	useEffect(() => {
		const actions = !!showRefresh
			? [
					{
						content: <FormattedMessage {...actionMessage} />,
						onClick: () => window.location.reload(),
					},
				]
			: [];
		const showFlag = async () => {
			const successFlag = await flags.showFlag({
				type,
				title: <FormattedMessage {...title} />,
				description: (
					<FormattedMessage
						{...description}
						values={{
							spaceName,
							classification:
								classification === NO_CLASSIFICATION ? 'unclassified' : <b>{classification}</b>,
						}}
					/>
				),
				actions,
			});
			setTimeout(() => flags.hideFlag(successFlag.id), 5000);
		};
		// eslint-disable-next-line no-console
		showFlag().catch(console.error);
	}, [flags, type, title, description, spaceName, showRefresh, classification]);
	return null;
};

const SaveFlagGetter: FC<SaveFlagProps> = (props) => {
	return <SaveFlagComponent {...props} />;
};

const SaveFlagWithErrorBoundary = withTransparentErrorBoundary({
	attribution: Attribution.DLP,
})(SaveFlagGetter);

export const SaveFlag: FC<SaveFlagProps> = (props) => <SaveFlagWithErrorBoundary {...props} />;
