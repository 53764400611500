export type ContentMode = 'archived' | 'draft' | 'live' | 'view' | 'edit';

export const getContentMode = (
	contentType: string | undefined,
	isArchived: boolean,
	isDraft: boolean,
	isViewEditOrLive: 'view' | 'edit' | 'live',
): ContentMode | undefined => {
	// archived takes priority, since other modes like 'view' can be in this state
	if (isArchived) {
		return 'archived';
	}

	// useGetPageMode *always* returns one of 'live', 'view', or 'edit', but these modes don't apply across all
	// content types - so the content type doesn't have a 'mode' and we should return undefined
	if (contentType === 'page' || contentType === 'blogpost') {
		// then draft takes next priority, since other modes like 'edit' can be in this state
		if (isDraft) {
			return 'draft';
		}

		// technically only pages can be live at the moment, and blogposts wouldn't ever return live, but they *could*
		return isViewEditOrLive;
	} else {
		return undefined;
	}
};
