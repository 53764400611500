import { LoadableLazy } from '@confluence/loadable';

export const ClassificationEditModalLazyLoader = LoadableLazy({
	name: 'ClassificationEditModalLazyLoader',

	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ClassificationEditModal" */ '../src/shared/ClassificationEditModal'
			)
		).ClassificationEditModal,
});
