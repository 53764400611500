import React, { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import type {
	ClassificationOption,
	DataClassificationLevelProps,
} from '@atlassian/data-classification-level';
import DataClassificationLevel from '@atlassian/data-classification-level';
import { type Placement } from '@atlaskit/popper';

import { ErrorDisplay } from '@confluence/error-boundary';
import { LoadableLazy } from '@confluence/loadable';

import type { ClassificationLevel } from '../ClassificationRadioOption';
import {
	ActionSubjectId,
	useTrackClassificationAnalytics,
} from '../hooks/useTrackClassificationAnalytics';
import { getPopupPlacementForTagVariant } from '../utils/getPopupPlacementForTagVariant';
import { TagButtonUIVariant } from '../constants/TagButtonUIVariant';
import { useComputeDefaultClassificationDropdownOption } from '../hooks/useComputeDefaultClassificationDropdownOption';

const ClassificationReadViewPopup = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ClassificationReadViewPopup" */ './ClassificationReadViewPopup'
			)
		).ClassificationReadViewPopup,
});

const ClassificationEditViewPopup = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ClassificationEditViewPopup" */ './ClassificationEditViewPopup'
			)
		).ClassificationEditViewPopup,
});

export enum TagButtonMenuBehavior {
	readOnly = 'readOnly',
	editOnly = 'editOnly',
	readAndEdit = 'readAndEdit',
}

type GenericClassificationTagButtonProps = {
	spaceKey: string;
	contentType: string;
	currentClassification: ClassificationLevel;
	spaceDefaultClassification: ClassificationLevel | null;
	spaceDefaultIsOverridden: boolean;
	getClassificationLevelFromId: (id?: string | null) => ClassificationLevel | undefined;
	onEditCancel?: () => void;
	onEditSubmit?: (c: ClassificationOption) => void;
	loading?: boolean;
	isPopupDisabled?: boolean;
	menuBehavior?: TagButtonMenuBehavior;
	variant?: TagButtonUIVariant;
	popupPlacementOverride?: Placement;
	spacing?: DataClassificationLevelProps['spacing'];
};

const DataClassificationLevelMemo = React.memo(DataClassificationLevel);

const messages = defineMessages({
	tooltipMessage: {
		id: 'data-classification.content-classification.tag-button.tooltip',
		defaultMessage: 'This {contentType} is classified as {classification}',
		description: 'Tooltip text for classification',
	},
});

export const GenericClassificationTagButton = ({
	spaceKey,
	contentType,
	currentClassification,
	spaceDefaultClassification,
	getClassificationLevelFromId,
	spaceDefaultIsOverridden,
	onEditCancel: onEditCancelProp,
	onEditSubmit: onEditSubmitProp,
	loading = false,
	isPopupDisabled = false,
	menuBehavior = TagButtonMenuBehavior.readOnly,
	variant = TagButtonUIVariant.default,
	popupPlacementOverride,
	spacing,
}: GenericClassificationTagButtonProps) => {
	const { formatMessage } = useIntl();
	const [isReadView, setIsReadView] = useState(true);
	const { trackEditPopupViewed } = useTrackClassificationAnalytics();
	const popupPlacement = popupPlacementOverride || getPopupPlacementForTagVariant(variant);

	useEffect(() => {
		if (menuBehavior === TagButtonMenuBehavior.editOnly) {
			setIsReadView(false);
		}
	}, [menuBehavior]);

	const { defaultClassificationOption, error: classificationOptionError } =
		useComputeDefaultClassificationDropdownOption({
			getClassificationLevelFromId,
			spaceDefaultClassificationLevel: spaceDefaultClassification,
		});

	const defaultSelectedEditOption = spaceDefaultIsOverridden
		? currentClassification
		: defaultClassificationOption;

	const getOnChangeLevelClick: () => (() => void) | undefined = () => {
		if (menuBehavior === TagButtonMenuBehavior.readOnly) return undefined;
		return () => {
			setIsReadView(false);
			handlePopupShown({ isOpen: true, shouldForce: true });
		};
	};

	const onEditCancel = (closePopup: () => void) => {
		onEditCancelProp && onEditCancelProp();
		closePopup();
		if (menuBehavior !== TagButtonMenuBehavior.editOnly) {
			setIsReadView(true);
		}
	};

	const onEditSubmit = (classification: ClassificationOption, closePopup: () => void) => {
		onEditSubmitProp && onEditSubmitProp(classification);
		closePopup();
		if (menuBehavior !== TagButtonMenuBehavior.editOnly) {
			setIsReadView(true);
		}
	};

	const handlePopupShown = ({
		isOpen,
		shouldForce = false,
	}: {
		isOpen: boolean;
		shouldForce?: boolean;
	}) => {
		if (isOpen) {
			const shouldTrackEvent = !isReadView || shouldForce;
			if (shouldTrackEvent) {
				trackEditPopupViewed(ActionSubjectId.CONTENT_TYPE_TITLE_DATA_CLASSIFICATION_POPUP);
			}
		}
	};

	const renderPopupComponent = (closePopup: () => void) => {
		return isReadView ? (
			<ClassificationReadViewPopup
				name={currentClassification.name}
				guideline={currentClassification.guideline}
				onChangeLevelClick={getOnChangeLevelClick()}
			/>
		) : (
			<ClassificationEditViewPopup
				spaceKey={spaceKey}
				selectedOption={defaultSelectedEditOption}
				defaultOption={defaultClassificationOption}
				onEditCancel={() => onEditCancel(closePopup)}
				onEditSubmit={(classification) => onEditSubmit(classification, closePopup)}
				loading={loading}
			/>
		);
	};

	return (
		<>
			{classificationOptionError ? <ErrorDisplay error={classificationOptionError} /> : null}
			<DataClassificationLevelMemo
				testId="data-classification-level"
				name={currentClassification.name}
				guideline={currentClassification.guideline}
				tooltip={formatMessage(messages.tooltipMessage, {
					contentType,
					classification: currentClassification.name,
				})}
				color={currentClassification.color}
				isPopupDisabled={isPopupDisabled}
				// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
				renderPopupComponent={!process.env.REACT_SSR ? renderPopupComponent : undefined}
				// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
				onPopupShown={!process.env.REACT_SSR ? () => handlePopupShown({ isOpen: true }) : undefined}
				isIconOnly={variant === TagButtonUIVariant.iconOnly}
				popupPlacement={popupPlacement}
				spacing={spacing}
			/>
		</>
	);
};
