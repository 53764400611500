import { LoadableAfterPaint } from '@confluence/loadable';

export const WorkflowStatePageEntryLoader = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-WorkflowStatePageEntry" */ '../src/WorkflowStatePageEntry'
			)
		).WorkflowStatePageEntry,
});

export const WorkflowStatusLoader = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-WorkflowStatus" */ '../src/WorkflowStatus'))
			.WorkflowStatusComponent,
});
